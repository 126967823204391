import {
  Controller
} from "@hotwired/stimulus";

// 仕入商品フォームの開閉によって送信するパラメータやアイコンを切り替える
export default class extends Controller {
  static values = { formIsOpen: Boolean, isshipped: Boolean };
  // カテゴリによって入力可能か変わる項目
  static targets = [
    'purchaseType',
    'contractCode',
    'contractQuantity',
    'stockCheckQuantity',
    'supplierProductCode',
    'inboundNo',
    'lotNo',
    'contractDate',
    'supplierProductName',
    'deliveryStartDate',
    'deliveryDueDate',
    'averageWeight',
  ];

  connect() {
    this.disableInputByPurchaseType(this.purchaseTypeTarget.value);
  }

  toggle() {
    this.formIsOpenValue = !this.formIsOpenValue;

    if(this.formIsOpenValue) {
      this.makeHiddenValueTrue();
      this.changeChevronDown();
    } else {
      this.makeHiddenValueFalse();
      this.changeChevronRight()
    }
  }

  makeHiddenValueTrue() {
    document.getElementById('form_is_open').value = true;
  }

  makeHiddenValueFalse() {
    document.getElementById('form_is_open').value = false;
  }

  // アイコンを右向きに変える
  changeChevronRight() {
    const iconElement = this.createIconElement('right');
    const targetElement = document.getElementById('icon-area');
    targetElement.replaceWith(iconElement);
  }

  // アイコンを下向きに変える
  changeChevronDown() {
    const iconElement = this.createIconElement('down')
    const targetElement = document.getElementById('icon-area');
    targetElement.replaceWith(iconElement);
  }

  // 指定された向きのchevronアイコンを作る
  createIconElement(direction) {
    const iconElement = document.createElement('i');
    iconElement.classList.add('bi', `bi-chevron-${direction}`);
    iconElement.id = 'icon-area';
    return iconElement;
  }

  // カテゴリ選択時に値を読み取って、特定のinputをdisableさせるメソッドに投げる
  purchaseTypeChangeActionListen() {
    this.disableInputByPurchaseType(this.purchaseTypeTarget.value);
  }

  // カテゴリ別で入力できる項目を絞る
  // 仕入商品マスタシート（https://docs.google.com/spreadsheets/d/1vaAlT6SBrVxrrYZ2BILjI27B1z6-ypad5sPIifleIxw/edit#gid=1705328611）の１行目を参考
  disableInputByPurchaseType(purchaseType) {
    console.log(purchaseType);
    this.removeDisabledOnAllTarget() // 一旦全てのdisabled属性を取り除く
    switch(purchaseType) {
      case "direct":
        this.contractCodeTarget.setAttribute("disabled", "");
        // 編集画面では入力不可
        if (this.isshippedValue) {
          this.contractQuantityTarget.setAttribute("disabled", "")
          this.inboundNoTarget.setAttribute("disabled", "");
        }
        this.stockCheckQuantityTarget.setAttribute("disabled", "");
        this.supplierProductCodeTarget.setAttribute("disabled", "");
        this.contractDateTarget.setAttribute("disabled", "");
        this.deliveryStartDateTarget.setAttribute("disabled", "");
        this.deliveryDueDateTarget.setAttribute("disabled", "");
        break;
      case "require_transfer":
        this.inboundNoTarget.setAttribute("disabled", "");
        break;
      case "actual":
        this.contractCodeTarget.setAttribute("disabled", "");
        this.stockCheckQuantityTarget.setAttribute("disabled", "");
        this.supplierProductCodeTarget.setAttribute("disabled", "");
        this.inboundNoTarget.setAttribute("disabled", "");
        this.lotNoTarget.setAttribute("disabled", "");
        this.contractDateTarget.setAttribute("disabled", "");
        this.deliveryStartDateTarget.setAttribute("disabled", "");
        this.deliveryDueDateTarget.setAttribute("disabled", "");
        this.averageWeightTarget.setAttribute("disabled", "");
        break;
      case "old_consignment":
        this.contractCodeTarget.setAttribute("disabled", "");
        this.stockCheckQuantityTarget.setAttribute("disabled", "");
        this.supplierProductCodeTarget.setAttribute("disabled", "");
        this.lotNoTarget.setAttribute("disabled", "");
        this.contractDateTarget.setAttribute("disabled", "");
        this.deliveryStartDateTarget.setAttribute("disabled", "");
        this.deliveryDueDateTarget.setAttribute("disabled", "");
        break;
    }
  }

  // targetに指定されているinputのdisabled属性を取り除く
  removeDisabledOnAllTarget() {
    this.contractCodeTarget.removeAttribute("disabled");
    this.contractQuantityTarget.removeAttribute("disabled");
    this.stockCheckQuantityTarget.removeAttribute("disabled");
    this.supplierProductCodeTarget.removeAttribute("disabled");
    this.inboundNoTarget.removeAttribute("disabled");
    this.lotNoTarget.removeAttribute("disabled");
    this.contractDateTarget.removeAttribute("disabled");
    this.supplierProductNameTarget.removeAttribute("disabled");
    this.deliveryStartDateTarget.removeAttribute("disabled");
    this.deliveryDueDateTarget.removeAttribute("disabled");
  }

  // targetに指定されているinputをdisableにする
  setDisabledOnAllTarget() {
    this.contractCodeTarget.setAttribute("disabled", "");
    this.contractQuantityTarget.setAttribute("disabled", "");
    this.stockCheckQuantityTarget.setAttribute("disabled", "");
    this.supplierProductCodeTarget.setAttribute("disabled", "");
    this.inboundNoTarget.setAttribute("disabled", "");
    this.lotNoTarget.setAttribute("disabled", "");
    this.contractDateTarget.setAttribute("disabled", "");
    this.supplierProductNameTarget.setAttribute("disabled", "");
    this.deliveryStartDateTarget.setAttribute("disabled", "");
    this.deliveryDueDateTarget.setAttribute("disabled", "");

  }
}